<template>
  <Layout tituloPagina="Facturas | Series">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="series"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="offset-md-2 col-md-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarSeries()
          "
          :datoini="dato"
          placeholder="Dato de las series de facturas"
        ></filtrador>
      </div>
      <div class="col-md-2 col-3 text-right">
        <router-link
          :to="{ name: 'nuevaSerieFacturas' }"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="tblSeries table table-hover table-nowrap" id="tabla-series-facturas">
            <thead>
              <tr>
                <th style="width: 100px">Prefijo</th>
                <th style="width: 100px">Consecutivo</th>
                <th>Nombre</th>
                <th style="width: 180px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="serie in series" :key="serie.id">
                <td>{{ serie.prefijo }}</td>
                <td>{{ serie.consecutivo }}</td>
                <td>{{ serie.nombre }}</td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'edicionSerieFacturas',
                      params: { id: serie.id }
                    }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminarSerie(serie)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import CmpSecciones from './CmpSecciones.vue'
import SerieFacturasSrv from '@/services/SerieFacturasSrv.js'
import Filtrador from '@/components/Filtrador.vue'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoSeriesFacturas',
  components: { Layout, CmpSecciones, Filtrador },
  data() {
    return {
      dato: '',
      baseUrl: window.location.origin,
      series: []
    }
  },

  created: function() {
    var self = this

    // Refresca las series
    self.refrescarSeries()
  },

  methods: {
    preguntaEliminarSerie: function(serie) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar serie",
        html: '¿Desea eliminar la serie <strong>' + serie.prefijo + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SerieFacturasSrv.eliminar(serie.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarSeries()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar la serie'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarSeries: function() {
      var self = this,
          params = { dato: self.dato }

      iu.spinner.mostrar('#tabla-series-facturas')

      SerieFacturasSrv.seriesJSON(params).then(response => {
        self.series = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las series'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-series-facturas')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-series-facturas')
  }
}
</script>

<style scoped>

</style>