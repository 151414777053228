<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'facturas'
          }"
          :to="{ name: 'listadoFacturas' }"
        >
          Facturas
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoSeriesFacturas' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'series'
          }"
        >
          Series
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light d-none d-sm-block"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CmpSecciones',
  props: ['seccion'],
  computed: {
    usuario: function() {
      return this.$store.state.todo.usuario
    }
  }
}
</script>

<style scoped>
.no-cursor{
  cursor: default;
}
</style>